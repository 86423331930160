import _ from 'lodash';

import { NodeIds } from '@breathelife/insurance-form-builder';
import { QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { InstanceScope, QuestionnaireVersionPricingNodeIds, VersionedAnswers } from '@breathelife/types';
import { AdoMinMaxConfig, EquivalentSingleAgeConfig } from '../../Models/PlatformFeatures';

type HasIncludesParams = {
  answers: VersionedAnswers;
  featureConfigsAdoMinMax: AdoMinMaxConfig;
  featureConfigsESA: EquivalentSingleAgeConfig;
  updatedNodeIds: string[];
  questionnaireEngine: QuestionnaireEngine;
  pricingFieldIdentifiers?: QuestionnaireVersionPricingNodeIds;
};

// Checks whether the conditions are met to fetch extra includes values for pricing
export function hasIncludes(props: HasIncludesParams): { ado: boolean; esa: boolean } {
  const ado = shouldIncludeAdoMinMax(props);
  const esa = shouldFetchESA(props);

  return {
    ado,
    esa,
  };
}

function shouldIncludeAdoMinMax({
  answers,
  updatedNodeIds,
  featureConfigsAdoMinMax,
  questionnaireEngine,
  pricingFieldIdentifiers,
}: HasIncludesParams): boolean {
  if (!featureConfigsAdoMinMax.enabled) return false;

  const fieldIdentifiersToWatch = pricingFieldIdentifiers
    ? pricingFieldIdentifiers.nodeIds
    : featureConfigsAdoMinMax.nodeIdsAffectingAdoMinMax;
  const updatedIdentifiersFields = _.isArray(fieldIdentifiersToWatch)
    ? _.intersection(fieldIdentifiersToWatch, updatedNodeIds)
    : [];
  const isUpdatedFieldOnlyAdoAmount =
    updatedIdentifiersFields.length === 1 &&
    featureConfigsAdoMinMax.adoCoverageAmount &&
    updatedIdentifiersFields.includes(featureConfigsAdoMinMax.adoCoverageAmount);
  const shouldRefreshAdo = updatedIdentifiersFields.length > 0 && !isUpdatedFieldOnlyAdoAmount;

  const adoOptionNodeId = featureConfigsAdoMinMax.adoOptionNodeId;
  if (!adoOptionNodeId || !shouldRefreshAdo) return false;

  const proposedInsuredCount = questionnaireEngine.getRepetitionCount(answers, NodeIds.insuredPeople, {});
  if (!proposedInsuredCount) return false;

  let shouldRefetch: boolean = false;
  for (let proposedInsuredIndex = 0; proposedInsuredIndex < proposedInsuredCount; proposedInsuredIndex++) {
    const scope: InstanceScope = {
      [NodeIds.insuredPeople]: proposedInsuredIndex,
    };

    const adoOption = questionnaireEngine.getAnswer(answers, adoOptionNodeId, scope);
    const adoIsNotEnabled = !adoOption || adoOption === 'no';
    if (!adoIsNotEnabled) {
      shouldRefetch = true;
      break;
    }
  }
  return shouldRefetch;
}

// Checks whether the conditions are met to fetch Equivalent Single Age (ESA)
function shouldFetchESA({
  answers,
  updatedNodeIds,
  featureConfigsESA,
  questionnaireEngine,
}: HasIncludesParams): boolean {
  if (
    !featureConfigsESA.enabled ||
    !featureConfigsESA.selectedProductNodeId ||
    !featureConfigsESA.jointProducts ||
    featureConfigsESA.jointProducts.length === 0 ||
    !featureConfigsESA.insuredInfoNodeIds ||
    featureConfigsESA.insuredInfoNodeIds.length === 0
  )
    return false;

  const nodeIdsAffectingESA = [featureConfigsESA.selectedProductNodeId, ...featureConfigsESA.insuredInfoNodeIds];
  const updatedESAFields = _.isArray(nodeIdsAffectingESA) ? _.intersection(nodeIdsAffectingESA, updatedNodeIds) : [];
  if (updatedESAFields.length === 0) return false;

  const proposedInsuredCount = questionnaireEngine.getRepetitionCount(answers, NodeIds.insuredPeople, {});
  if (!proposedInsuredCount || proposedInsuredCount !== 2) return false;

  let isJointProduct = false;
  let isAllInsuredInfoAvailable = true;

  for (let proposedInsuredIndex = 0; proposedInsuredIndex < proposedInsuredCount; proposedInsuredIndex++) {
    const scope: InstanceScope = {
      [NodeIds.insuredPeople]: proposedInsuredIndex,
    };

    const selectedProduct = questionnaireEngine.getAnswer(answers, featureConfigsESA.selectedProductNodeId, scope);
    if (selectedProduct || featureConfigsESA.jointProducts.includes(selectedProduct as string)) {
      isJointProduct = true;
    }

    for (const nodeId of featureConfigsESA.insuredInfoNodeIds) {
      if (!questionnaireEngine.getAnswer(answers, nodeId, scope)) {
        isAllInsuredInfoAvailable = false;
      }
    }
  }

  return isJointProduct && isAllInsuredInfoAvailable;
}
